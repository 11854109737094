import { Badge, Button, CardBody, Col, Row } from 'reactstrap';
import './job-card.css';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'store/app.context';
import NotLoggedPopUp from './not-logged-popup';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
// import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
// import { API_BASE_URL } from 'config/core';
import { useHistory } from 'react-router-dom';
import { MISSION_STATUS } from 'config/core';

export default function JobCard(props) {

  const history = useHistory()
  const { languageState, userState } = useContext(AppContext);
  // const urlToShare = `${API_BASE_URL}jobs/share/${props.id}`
  const [allSkills, setAllSkills] = useState([])
  moment.locale(languageState.defaultLanguage.toLowerCase());

  useEffect(() => {
    if (props) {
      const array = [
        ...(props.missionsSkills || []),
        ...((props.tags || []).map((el) => {
          return { id: el, key: el }
        }))
      ]
      setAllSkills(array.sort((a, b) => a.key.localeCompare(b.key, undefined, { sensitivity: 'base' })))
    }

  }, [props])

  return (
    <>
      <CardBody>
        <div className="job-parent-container">
          {props?.isPublished && languageState.translation.COMMON.PUBLISHED + ': ' + moment(props?.createdAt).locale(languageState.defaultLanguage).format('DD MMMM YYYY h:mma')}
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs="12" sm="9" xl="10" className="job-description-container">
              <div className="job-locations flex-wrap">
                {
                  props?.status && (
                    <div className="job-location-item">
                      <Badge
                        className={`d-flex align-items-center badge-dot ${props.status === MISSION_STATUS.FINISHED
                          ? 'bg-exprimary-light'
                          : props.status === MISSION_STATUS.PUBLISHED
                            ? 'bg-primary-light'
                            : props.status === MISSION_STATUS.CANDIDATE_FOUND || props.status === MISSION_STATUS.ON_GOING || props.status === MISSION_STATUS.INTERVIEWING
                              ? 'bg-success-light'
                              : props.status === MISSION_STATUS.NOT_STARTED || props.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                ? 'bg-warning-light'
                                : 'bg-danger-light'
                          }`}
                      >
                        <i
                          className={
                            props.status === MISSION_STATUS.FINISHED
                              ? 'bg-exprimary'
                              : props.status === MISSION_STATUS.PUBLISHED
                                ? 'bg-primary'
                                : props.status === MISSION_STATUS.CANDIDATE_FOUND || props.status === MISSION_STATUS.ON_GOING || props.status === MISSION_STATUS.INTERVIEWING
                                  ? 'bg-success'
                                  : props.status === MISSION_STATUS.NOT_STARTED || props.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                    ? 'bg-warning'
                                    : 'bg-danger'
                          }
                        />
                        <span className="status">
                          {languageState.translation.COMMON.STATUS_LIST[props.status]}
                        </span>
                      </Badge>
                    </div>
                  )
                }
                {props?.city && (
                  <div className="job-location-item">
                    <i className="fas fa-map-marker-alt fa-sm" />
                    <p className="location-text">
                      {props?.city + ' ' + props?.country}
                    </p>
                  </div>
                )}
                <div className="job-location-item">
                  <i className="fas fa-clock fa-sm"></i>
                  <p className="location-text">
                    {props?.hoursPerWeek < 35 ? languageState.translation.COMMON.PART_TIME : languageState.translation.COMMON.FULL_TIME}
                  </p>
                </div>
                <div className="job-location-item">
                  <p className="location-text">
                    {languageState.translation.COMMON.PUBLISHED} : {moment(props?.createdAt).format('DD MMMM YYYY')}
                  </p>
                </div>
                {props?.remote && (
                  <div className="job-location-item">
                    <i className="fas fa-home fa-sm"></i>
                    <p className="location-text">{languageState.translation.COMMON.REMOTE_AVAILABLE}</p>
                  </div>
                )}
              </div>
              <div className="company-details">
                <h3> {props?.functionTitle || '---'}</h3>
              </div>
              {allSkills && allSkills.length ? (
                <div className="required-skills">
                  <p className="required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
                  {allSkills?.map((missionSkill) => (
                    <div key={missionSkill.id} className="skill">
                      <p className="skill-text text-nowwrap">
                        {missionSkill.key}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </Col>
            <Col xs="12" sm="3" xl="2">
              {!props?.isPublished && (
                <div className="daily-rate-container">
                  {/* <p className="daily-rate">{languageState.translation.COMMON.DAILY_RATE}</p>
              <p className="daily-price">{props?.dailyBudget || 0} €</p> */}
                  <p className="number-of-applicants">
                    {languageState.translation.JOBBOARD.NUMBER_APPLICATIONS}:
                    <strong>{props?.applicants?.length || 0} </strong>
                  </p>
                  <p className="number-of-views">
                    {languageState.translation.JOBBOARD.NUMBER_VIEWS}: <strong> {props?.nbViews || 0} </strong>
                  </p>
                </div>
              )}
            </Col>
          </Row>
          <div className="button-container">
            <Button onClick={() => props.openDetails(props.id)} color='danger' className={props?.isPublished ? "view-details-secondary" : "view-details"}>{languageState.translation.COMMON.VIEW_DETAILS}</Button>
            {userState && userState.userInfo && (
              <Button disabled={props.jobSaved} onClick={() => props.saved ? props.deleteSavedJob(props.id) : props.saveJob(props.id)} color='danger' className={props?.isPublished ? "view-details-secondary" : "view-details"}><i className="fas fa-bookmark fa-sm mr-1" /> {props.saved ? languageState.translation.COMMON.DELETE_SAVE_JOB : languageState.translation.COMMON.SAVE_JOB}</Button>
            )}
            <Button color='primary' onClick={() => {
              history.push({
                pathname: '/private/chat',
                state: { pathJob: `${window.location.origin}/private/jobs/job-details/${props.id}` }
              })
            }} className="share">{languageState.translation.COMMON.HELP_OTHER_FREELANCERS}</Button>
            {/* <div className="d-flex bg-white">
              <TwitterShareButton
                url={urlToShare}
                className="mr-2"
                children={<TwitterIcon size={36} round />}
              />
              <FacebookShareButton
                className="mr-2"
                children={<FacebookIcon size={36} round />}
                url={urlToShare}
              />
              <LinkedinShareButton
                children={<LinkedinIcon size={36} round />}
                url={urlToShare} />
            </div> */}
          </div>
        </div>
        <NotLoggedPopUp modal={props.modal} setModal={props.setModal} />
      </CardBody>
    </>
  )
}
