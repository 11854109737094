import TYPES from './jobs.types';
import reducer from './jobs.reducer';

const INITIAL_STATE = {
    jobs: [],
    jobSaved: [],
    job: [],
    categories: [],
    config: {}

};

const JOBS_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default JOBS_STATE;
