import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';
import { Button, Container } from 'reactstrap';
import STEPPER_STATE from 'store/stepper';
import './stepper.css';

const Stepper = ({ setOnboarding }) => {
  const { stepperState, dispatchStepperState, languageState } = useContext(AppContext);

  return (
    <Container className='w-100 d-flex mb-3 mt-3'>
      <Container className='d-flex justify-content-center ml-5'>
        {
          // stepperState.currentStep === 3
          //   ? <div className='font-weight-bold text-center my-3'>
          //     {languageState.translation.STEPPER.BASIC_INFO_SECTION_6}
          //   </div>
          //   : 
          <div className='text-uppercase font-weight-bold'>
            {`${languageState.translation.COMMON.SETUP_STEP} ${stepperState.currentStep}/2`}
          </div>

        }
      </Container>
    </Container>

  );
};

export default Stepper;
