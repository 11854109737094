import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Card, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { useHistory } from 'react-router-dom';
import useMissionService from 'services/missions/mission.service';
import { MISSION_STATUS } from 'config/core';
import { toast } from 'react-toastify';
import DeleteModalGeneric from 'components/layout/DeleteModal/DeleteModalGeneric';
import * as moment from 'moment';
import HeaderMissionList from './overview/header-mission-list';
import PaginationComponent from 'components/pagination/PaginationComponent';
import APP_ROUTES from 'navigation/app.routes';

const MissionOverview = ({ type }) => {
  const history = useHistory();
  const { getMisions, deleteMission, updateMission } = useMissionService();
  const { languageState, missionState } = useContext(AppContext);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedMission, setSelectedMission] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listMission, setListMission] = useState([]);
  const [totalItems, setTotalItems] = useState(0)
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState(null)
  const [missionStatus, setMissionStatus] = useState(null)
  const [sortedColumn, setSortedColumn] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)

  useEffect(() => {
    getMisions({ limit: pageSize, page: currentPage, searchKey: searchKey, status: missionStatus, sortColumn: sortedColumn, sortOrder: sortOrder });
  }, [pageSize, currentPage, searchKey, missionStatus, sortedColumn, sortOrder]);

  useEffect(() => {
    if (missionState && missionState.missions && missionState.missions.items) {
      setListMission(missionState.missions.items)
      setTotalItems(missionState.missions.meta.totalItems)
    }
  })

  const handleSort = (column) => {
    const order = (sortedColumn === column && sortOrder === 'ASC') ? 'DESC' : 'ASC';
    setSortedColumn(column);
    setSortOrder(order);
  };

  const closeOrActivateMission = async (mission, status) => {
    const reqData = {
      status,
    };
    const result = await updateMission(mission.id, reqData);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.SUCCESS_UPDATE_MISSION, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getMisions();
    }
  };

  const removeMission = (mission) => {
    setSelectedMission(mission);
    setModalDelete(true);
  };

  const handleRemoveMission = async () => {
    const result = await deleteMission(selectedMission.id);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.MISSION_DELETED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setModalDelete(false);
      setSelectedMission(null);
      getMisions();
    }
  };

  const handlePerRowsChange = (newPerPage) => {
    setPageSize(newPerPage.value);
  };



  return (
    <div className='mission-overview-content'>
      <Container fluid className="mt-4">
        <Row>
          <div className="col">
            <Card className='p-4'>
              <CardHeader className='px-0 pb-4 pt-0 border-0'>
                <HeaderMissionList missionCount={totalItems || 0} searchKey={searchKey} setSearchKey={setSearchKey} missionStatus={missionStatus} setMissionStatus={setMissionStatus} handlePerRowsChange={handlePerRowsChange} pageSize={pageSize} />
              </CardHeader>
              {listMission && listMission.length ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="NAME" onClick={() => handleSort('NAME')}>
                        {languageState.translation.COMMON.NAME}
                      </th>
                      <th className="sort" data-sort="APPLICANTS_NUMBER" onClick={() => handleSort('APPLICANTS_NUMBER')}>
                        {languageState.translation.COMMON.APPLICANTS_NUMBER}
                      </th>
                      <th className="sort" data-sort="JOB_POSTER" onClick={() => handleSort('JOB_POSTER')}>
                        {languageState.translation.COMMON.JOB_POSTER}
                      </th>
                      <th className="sort" data-sort="BUDGET" onClick={() => handleSort('BUDGET')}>
                        {languageState.translation.COMMON.BUDGET}
                      </th>
                      <th className="sort" data-sort="STATUS" onClick={() => handleSort('STATUS')}>
                        {languageState.translation.COMMON.STATUS}{' '}
                      </th>
                      <th className="sort" data-sort="PUBLICATION_DATE" onClick={() => handleSort('PUBLICATION_DATE')}>
                        {languageState.translation.COMMON.PUBLICATION_DATE}{' '}
                      </th>
                      {/* <th className="sort">
                          {languageState.translation.COMMON.FREELANCERS}{' '}
                        </th> */}
                      <th scope="col" >{languageState.translation.COMMON.ACTIONS}</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {listMission.map((mission) => (
                      <tr
                        key={mission.id}
                        className="recruiter-hover"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            'edit-mission/' + mission.id
                          );
                        }}
                      >
                        <th scope="row">
                          <Media className="align-items-center w-22rem">
                            <Media>
                              <span className="name mb-0 text-sm c-pointer text-prewrap">
                                {mission.functionTitle ? mission.functionTitle : 'mission'}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <th scope="row">{mission?.applicants && mission.applicants.length || 0}</th>
                        <th scope="row">{mission?.firstName}</th>
                        <td className="budget c-pointer">
                          {' '}
                          {`${mission?.dailyBudget
                            ? mission?.dailyBudget
                            : 0
                            } ${mission?.currency}`}{' '}
                        </td>
                        <td>
                          {mission.status && (
                            <Badge
                              className={`d-flex align-items-center badge-dot ${mission.status === MISSION_STATUS.FINISHED
                                ? 'bg-exprimary-light'
                                : mission.status === MISSION_STATUS.PUBLISHED
                                  ? 'bg-primary-light'
                                  : mission.status === MISSION_STATUS.CANDIDATE_FOUND || mission.status === MISSION_STATUS.ON_GOING || mission.status === MISSION_STATUS.INTERVIEWING
                                    ? 'bg-success-light'
                                    : mission.status === MISSION_STATUS.NOT_STARTED || mission.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                      ? 'bg-warning-light'
                                      : 'bg-danger-light'
                                }`}
                            >
                              <i
                                className={
                                  mission.status === MISSION_STATUS.FINISHED
                                    ? 'bg-exprimary'
                                    : mission.status === MISSION_STATUS.PUBLISHED
                                      ? 'bg-primary'
                                      : mission.status === MISSION_STATUS.CANDIDATE_FOUND || mission.status === MISSION_STATUS.ON_GOING || mission.status === MISSION_STATUS.INTERVIEWING
                                        ? 'bg-success'
                                        : mission.status === MISSION_STATUS.NOT_STARTED || mission.status === MISSION_STATUS.WAITING_FOR_APPROVAL
                                          ? 'bg-warning'
                                          : 'bg-danger'
                                }
                              />
                              <span className="status">
                                {languageState.translation.COMMON.STATUS_LIST[mission.status]}
                              </span>
                            </Badge>
                          )}
                        </td>
                        <th scope="row">{moment(mission?.createdAt).format('DD/MM/YYYY')}</th>
                        {/* <td>
                              <div className="avatar-group d-flex">
                                {mission?.freelancers &&
                                  mission?.freelancers.splice(0, 4).map((data, index) => (
                                    <React.Fragment key={index}>
                                      <a
                                        key={index}
                                        className="avatar avatar-sm rounded-circle"
                                        href="#"
                                        id={`tooltip${index}`}
                                        onClick={(e) =>
                                          e.preventDefault()
                                        }
                                      >
                                        {data?.profileImage ? (
                                          <img
                                            className="avatar-sm"
                                            alt="..."
                                            src={`${IMG_PREFIX}${data?.profileImage}`}
                                          />
                                        ) : (
                                          <Avatar
                                            firstName={
                                              data?.firstName
                                            }
                                            lastName={
                                              data?.lastName
                                            }
                                            name={data?.name}
                                            forNavbar
                                          />
                                        )}
                                      </a>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`tooltip${index}`}
                                      >
                                        <span>{data?.firstName &&
                                          data?.lastName
                                          ? `${data?.firstName} ${data?.lastName}`
                                          : data?.name}</span>
                                        <span>
                                          {data?.email}
                                        </span>
                                      </UncontrolledTooltip>
                                    </React.Fragment>
                                  )
                                  )}
                                {mission.freelancers.length > 4 && (
                                  <React.Fragment>
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      id={`tooltip-points`}
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <Avatar name="..." forNavbar />
                                    </a>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`tooltip-points`}
                                    >
                                      {`${languageState.translation.COMMON.AND
                                        }${mission.freelancers.length - 4} ${languageState.translation.COMMON
                                          .OTHERS
                                        }`}
                                    </UncontrolledTooltip>
                                  </React.Fragment>
                                )}
                              </div>
                            </td> */}
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <Button
                              Button
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push('edit-mission/' + mission?.id);
                              }}
                              outline
                              className="action-icon"
                            >
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push('edit-mission/' + mission?.id);
                              }}
                              outline
                              className="action-icon"
                            >
                              <i className="fa fa-eye" />
                            </Button>
                            <Button
                              color="danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeMission(mission)
                              }}
                              outline
                              className="action-icon"
                            >
                              <i className="fa fa-trash" />
                            </Button>
                            {mission.status === 'ON_GOING' && (
                              <UncontrolledDropdown nav>
                                <DropdownToggle
                                  className="nav-link pl-0 pr-0"
                                  color=""
                                  tag="a"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Button
                                    color="primary"
                                    className="action-icon"
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu right container="body" onClick={(e) => e.stopPropagation()}>
                                  {/* {mission.status !==
                                    MISSION_STATUS.CLOSED ? (
                                    <DropdownItem
                                      className="noti-title hover_title"
                                      header
                                      tag="div"
                                    >
                                      <h5
                                        className="text-overflow m-0"
                                        role="button"
                                        onClick={() =>
                                          closeOrActivateMission(
                                            mission,
                                            MISSION_STATUS.CLOSED
                                          )
                                        }
                                      >
                                        {
                                          languageState.translation
                                            .RECRUITER.MISSIONS
                                            .CLOSE_MISSION
                                        }
                                      </h5>
                                    </DropdownItem>
                                  ) : (
                                    <DropdownItem
                                      className="noti-title hover_title"
                                      header
                                      tag="div"
                                    >
                                      <h5
                                        className="text-overflow m-0"
                                        role="button"
                                        onClick={() =>
                                          closeOrActivateMission(
                                            mission,
                                            MISSION_STATUS.NOT_STARTED
                                          )
                                        }
                                      >
                                        {
                                          languageState.translation
                                            .RECRUITER.MISSIONS
                                            .ACTIVATE_MISSION
                                        }
                                      </h5>
                                    </DropdownItem>
                                  )} */}
                                  {/* <DropdownItem
                                        className="noti-title hover_title"
                                        header
                                        tag="div"
                                      >
                                        <h5 className="text-overflow m-0">
                                          {
                                            languageState.translation.RECRUITER
                                              .MISSIONS.SHARE_WITH_CO
                                          }
                                        </h5>
                                      </DropdownItem> */}
                                  <DropdownItem
                                    className="noti-title hover_title"
                                    header
                                    tag="div"
                                  >
                                    <h5
                                      className="text-overflow m-0"
                                      onClick={() => history.push({ pathname: `/private${APP_ROUTES.RECRUITER.SHARE_ONBOARDING}`, state: { value: mission.id, label: mission.functionTitle } })}
                                    >
                                      {languageState.translation.RECRUITER.MISSIONS.SEND_BRIEFING}
                                    </h5>
                                  </DropdownItem>
                                  <DropdownItem
                                    className="noti-title hover_title"
                                    header
                                    tag="div"
                                  >
                                    <h5
                                      className="text-overflow m-0"
                                      onClick={() => closeOrActivateMission(mission, 'CLOSED')}
                                    >
                                      {languageState.translation.RECRUITER.MISSIONS.CLOSE_REMOVE_MISSION}
                                    </h5>
                                  </DropdownItem>
                                  <DropdownItem
                                    className="noti-title hover_title"
                                    header
                                    tag="div"
                                  >
                                    <h5
                                      className="text-overflow m-0"
                                      onClick={() => { history.push('missions/contractors/' + mission?.id) }}
                                    >
                                      {languageState.translation.RECRUITER.MISSIONS.VIEW_CONTRACTORS}
                                    </h5>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </Table>
              ) : null}
              {totalItems === 0 && (
                <div className="d-flex justify-content-center my-5">
                  <h5>
                    {languageState.translation.RECRUITER.MISSIONS.NO_MISSION}
                  </h5>
                </div>
              )}
              {totalItems > 0 ?
                (
                  <PaginationComponent totalItems={totalItems} pageSize={pageSize} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                ) : null}
            </Card>
          </div>
        </Row>
      </Container>
      <DeleteModalGeneric
        onClick={handleRemoveMission}
        modalShow={modalDelete}
        closeModal={() => {
          setModalDelete(false);
        }}
        body={
          languageState.translation.RECRUITER.MISSIONS.DO_YOU_REALLY_MISSION
        }
      />
    </div>
  );
};

export default MissionOverview;
