const TYPES = {
    SET_JOBS: '[JOBS] SET_JOBS',
    GET_JOBS: '[JOBS] GET_JOBS',
    SET_JOBS_SAVED: '[JOBS] SET_JOBS_SAVED',
    GET_JOBS_SAVED: '[JOBS] GET_JOBS_SAVED',
    GET_JOB: '[JOBS] GET_JOB',
    GET_CATEGORIES: '[JOBS] GET_CATEGORIES',
};

export default TYPES;
