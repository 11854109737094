const { useContext } = require('react');
import JOBS_STATE from 'store/jobs/jobs.state';
import useHttpClient from '../http-client-hook';
const { AppContext } = require('store/app.context');

const useJobsService = () => {
  const { httpClient } = useHttpClient();
  const { dispatchJobsState } = useContext(AppContext);
  const getJobs = async (payload) => {
    const result = await httpClient.get('/jobs' + payload);
    if (result && result.status == 200) {
      dispatchJobsState({
        type: JOBS_STATE.types.SET_JOBS,
        jobs: result.data.items,
        config: result.data.meta
      });
    }
  };
  const getJobsSaved = async (payload) => {
    const result = await httpClient.get('/jobs/save-job' + payload);
    if (result && result.status == 200) {
      dispatchJobsState({
        type: JOBS_STATE.types.SET_JOBS_SAVED,
        jobSaved: result.data
      });
    }
  };
  const getJob = async (id) => {
    const result = await httpClient.get('/jobs/' + id);
    return result.data;
  };

  const getJobDetail = (id) => {
    return httpClient.get('/jobs/' + id);
  };
  const postViewJob = (id) => {
    return httpClient.post('/jobs/view/' + id);
  };
  const applyJob = async (id, data) => {
    const result = await httpClient.post('/jobs/apply/' + id, data);

    if (result && result.status == 201) {
      return true;
    }
  };
  const checkIsApplied = async (id) => {
    const result = await httpClient.get('/jobs/check-application/' + id);
    if (result && result.status == 200) {
      return result.data;
    }
    return false;
  };
  const checkIsSaved = async (id) => await httpClient.get(`/jobs/save-job/${id}`);

  const deleteApplication = async (id) => {
    const result = await httpClient.delete('/jobs/delete-application/' + id);
    if (result && result.status == 200) {
      return true;
    }
    return false;
  };
  const getAppliedUserByJobs = async (id, data) => {
    const result = await httpClient.post('/jobs/applied-users-by-job/' + id, data);
    if (result && result.status == 200 || result.status == 201) {
      return result.data;
    }
    return [];
  };
  const getContractorsUserByJobs = async (id) => {
    const result = await httpClient.get(`/missions/${id}/contractors`);
    if (result && result.status == 200 || result.status == 201) {
      return result.data;
    }
    return [];
  };
  const updateApplication = async (id, payload) => {
    const result = await httpClient.patch(
      '/jobs/update-application/' + id,
      payload
    );
    if (result && result.status == 200) {
      return true;
    }
    return false;
  };
  const getCategories = async () => {
    const result = await httpClient.get('/categories');

    if (result && result.status == 200) {
      dispatchJobsState({
        type: JOBS_STATE.types.GET_CATEGORIES,
        categories: result.data,
      });
    }
    return [];
  };

  const saveNewJob = async (id) => await httpClient.post(`/jobs/save-job/${id}`);

  const deleteSaved = async (id) => await httpClient.delete(`/jobs/save-job/${id}`);

  return {
    getJobDetail,
    postViewJob,
    getJobs,
    getJobsSaved,
    getJob,
    applyJob,
    checkIsApplied,
    checkIsSaved,
    deleteApplication,
    getAppliedUserByJobs,
    getContractorsUserByJobs,
    getCategories,
    updateApplication,
    saveNewJob,
    deleteSaved
  };
};

export default useJobsService;
