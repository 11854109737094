import classnames from 'classnames';
import moment from 'moment';
import React, { useContext } from 'react';
import { AppContext } from 'store/app.context';
import { isMessageOwner } from 'utils';
import FileMessage from './fileMessage';
import { Label } from 'reactstrap';
import './users-list.scss'

const Message = ({ room, message }) => {
  const { chatState } = useContext(AppContext);

  // Function to convert plain text URLs to clickable links
  const convertLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className={`chat-message-link chat-white text-underline${isMessageOwner(message, chatState?.chatUserId) ? ' text-white' : ' color-gray-700'}`}>
          {part}
        </a>
      ) : (
        part
      )
    );
  }

  return (
    <div
      className={classnames('row', {
        'justify-content-end': isMessageOwner(message, chatState?.chatUserId),
        'justify-content-start': !isMessageOwner(
          message,
          chatState?.chatUserId
        ),
      })}
    >
      <div className='d-flex'>

        {message.status === 'IMPORTANT' || message.isImportant ? (
          <Label className={classnames('m-0 p-3 d-flex align-items-center font-weight-bolder',
            {
              ' active-contact': !isMessageOwner(message, chatState?.chatUserId),
              ' not-active-contact': isMessageOwner(message, chatState?.chatUserId),
              ' text-right': isMessageOwner(message, chatState?.chatUserId),
            }
          )} >
            !
          </Label>
        ) : null}
        <div
          className={classnames('p-2 d-flex flex-column col-auto shadow-lg ',
            {
              'active-contact ': isMessageOwner(message, chatState?.chatUserId),
              'text-right': isMessageOwner(message, chatState?.chatUserId),
              'rounded-right': message.status === 'IMPORTANT',
              'rounded': message.status !== 'IMPORTANT'
            }
          )}
        >
          {!isMessageOwner(message, chatState?.chatUserId) && (
            <div className="d-flex">
              <span className="fw-800">{`${message?.author?.first_name} ${message?.author?.last_name.charAt(0)}. :`}</span>
            </div>
          )}
          <div className="text-justify chat-white font-weight-bold">
            {message.attachments && message.attachments.length ? (
              // <img
              //   className="chat-image"
              //   src={IMG_PREFIX + message?.attachments[0]?.file?.filename}
              // />
              <FileMessage path={message.attachments[0].url} />
            ) : (
              convertLinks(message?.content)
            )}
          </div>
          <small
            className={classnames('chat-white mt-1', {
              'ml-auto': isMessageOwner(message, chatState?.chatUserId),
            })}
          >
            {!isMessageOwner(message, chatState?.chatUserId) && (
              <i className="fas fa-clock mr-1" />
            )}
            {moment(message?.created_at).format('HH:mm a')}
            {isMessageOwner(message, chatState?.chatUserId) && (
              <React.Fragment>
                <i className="fas fa-check ml-1" />
              </React.Fragment>
            )}
          </small>
        </div>
      </div>
    </div>
  );
};

export default Message;
